import React from 'react'
import Layout from '../components/layout'
import CookieNotice from '../components/CookieNotice';

const NotFoundPage = () => (
  <Layout>
    <h1>Seite nicht gefunden</h1>
    <p>Die Seite konnte leider nicht gefunden werden.</p>
    <p>Bitte kehren Sie zurück zur <a href="/">Startseite</a></p>
    <CookieNotice />
  </Layout>
)

export default NotFoundPage
